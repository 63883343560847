.mat-dialog-container {
  background-color: $white;
  padding: 0 !important;
}

.star-dialog-backdrop {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(14, 14, 14, 0.82);
}
