.mat-menu-panel {
  .mat-menu-item {
    font-family: $font-family-semibold;
    display: flex;
    align-items: center;
    color: $purple-light;

    .mat-icon {
      color: $purple-light;
    }
  }
}
