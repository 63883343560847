/* Breeakpoints */
// Best practice is Mobile First: (min-width: $breakpoint)
$tiny: 480px !default; // or 'em' if you prefer, of course
$small: 576px !default;
$medium: 768px !default;
$large: 992px !default;
$extra-large: 1200px !default;
$xx-large: 1620px !default;
$xxx-large: 1920px !default;

/* Colors */
$white: #fff !default;
$grey-bg: #f5f6fa !default;
$grey-ultra: #fafafa !default;
$grey-lighter: #f0f0f0 !default;
$grey-light: #d0d0d0 !default;
$grey: #9f9f9f !default;
$grey-deep: #6d7278 !default;
$grey-dark: #38373a !default;
$black: #000 !default;

$blue-lighter: #bbe4fa !default;
$blue-light: #83cfff !default;
$blue-sky: #2d99ff !default;
$blue-deep: #008dd1 !default;
$blue-dark: #2e2b60 !default;
$green: #4ba37a !default;
$green-apple: #7baf43 !default;
$green-apple-light: #80b13d !default;
$green-jade: #00a37a !default;
$green-olive: #006234 !default;
$green-kabalite: #038c67 !default;
$yellow-sun: #f7b500 !default;
$yellow-tropical: #f9b61a !default;
$orange: #fa6400 !default;
$sanguine-orange: #eb3712 !default;
$fushia: #e31d5d !default;
$purple-flashy: #6236ff !default;
$purple: #312e60 !default;
$purple-darken: #3d1656 !default;
$purple-light: #5c2282 !default;
$purple-lighter: #830dd0 !default;
$purple-separator: #7a779d !default;
$red-bright: #e42c46 !default;

$gradient-terega-color1: #00a2dd;
$gradient-terega-color2: #72be44;
$gradient-purple-color1: #7a2d76;
$gradient-purple-color2: #4b138a;
$gradient-terega: linear-gradient(136deg, $gradient-terega-color1 -14%, $gradient-terega-color2 124%) !default;
$gradient-purple: linear-gradient(128deg, $gradient-purple-color1 2%, $gradient-purple-color2 98%) !default;

$color-base: $purple;
$background-base: $grey-bg;

/* Font families */
$font-family-base: hurme_regular, sans-serif !default;
$font-family-bold: hurme_bold, sans-serif !default;
$font-family-semibold: hurme_semibold, sans-serif !default;
$font-family-light: hurme_light, sans-serif !default;
$font-family-thin: hurme_thin, sans-serif !default;
$font-family-headings: $font-family-bold;

/* Font sizes */
// Font sizes (1.4rem value is "14px" equivalent)
$font-size-base: 1.4rem !default;
$font-sizes: (
  base: (
    mobile: 1.4rem,
    desktop: 1.4rem,
  ),
  h1: (
    mobile: 2.4rem,
    desktop: 2.4rem,
  ),
  h2: (
    mobile: 1.8rem,
    desktop: 1.8rem,
  ),
  h3: (
    mobile: 1.6rem,
    desktop: 1.6rem,
  ),
  h4: (
    mobile: 1.4rem,
    desktop: 1.4rem,
  ),
  h5: (
    mobile: 1.2rem,
    desktop: 1.2rem,
  ),
  h6: (
    mobile: 1rem,
    desktop: 1rem,
  ),
) !default;

// Line heights
$line-height-s: 1.3 !default;
$line-height-base: 1.5 !default;
$line-height-l: 1.7 !default;

// Default margin-bottom
$margin-bottom-base: 1rem !default;
$headings-margin-bottom: $margin-bottom-base * 0.5 !default;
$paragraph-margin-bottom: $margin-bottom-base !default;

// Font weights
$weight-light: 200 !default;
$weight-book: 300 !default;
$weight-regular: 400 !default;
$weight-medium: 500 !default;
$weight-bold: 700 !default;
$weight-bolder: 900 !default;

// z-indexes
//->bootstrap
$zindex-navigation: 1000 !default; //utilisé par le header
$zindex-dropdown: 2000 !default;
$zindex-tooltip: 3000 !default;
$zindex-modal: 4000 !default;
//->portail

$z-index-footer: 1;
$z-index-main: 100;
$z-index-menu: 200;
$z-index-consent-bar: 200;
$z-index-consent-bar--consent: 9999999999;
$zindex-event-carousel-btn: 10;
// $zindex-event-pin:;
$zindex-tooltip-text: 200;
$zindex-tooltip-content: 200;

$zindex-consumption-hourly-data: 0;

$zindex-tooltip: 200;
$zindex-graph-tooltip: 500;
$zindex-dialog-close-icon: 3;
$z-index-star-table-th: 200; // en commun avec $z-index-mat-list-header: 200;

$z-index-background-gradient: -1;

// Borders
$border-radius: 4px;

// Tables
$table-header-height: 66px;
$table-header-bg: $purple;
$table-header-border-color: $purple-separator;

$table-row-height: 46px;
$table-row-shadow: 0 0.5px 2px 0 rgba(43, 59, 93, 0.29);
$table-row-shadow-hover: 0 0 13px 0 rgba(81, 39, 127, 0.29), 0 1px 2px 0 rgba(81, 39, 127, 0.29);

// Components
$header-height: 7rem;
$footer-height: 7.8125rem;
$button-height: 3.6rem;
