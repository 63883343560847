.export-dialog__radio-group {
  .radio-button__label {
    color: $purple-light;
    @include font-size(h2);

    font-family: $font-family-bold;
    margin-right: 20px;
    pointer-events: none;
  }

  .mat-radio-disabled .radio-button__label {
    color: $grey;
  }

  .mat-radio-label-content {
    display: flex;
    align-items: center;
  }
}
