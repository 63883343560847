.mat-form-field {
  color: $purple-light;

  &.mat-form-field-disabled {
    .mat-form-field-underline {
      background-image: none;
      background-color: $grey-light;
    }

    .mat-form-field-prefix,
    .mat-form-field-suffix {
      color: $grey-light;
    }
  }

  &.mat-form-field-small {
    .mat-form-field-wrapper {
      padding-bottom: 0;

      .mat-form-field-infix {
        padding: 0.5em 0;
      }

      .mat-form-field-label-wrapper {
        top: -1.2em;
      }
    }

    &:not(.mat-form-field-disabled, .mat-form-field-invalid) .mat-form-field-flex:hover {
      .mat-form-field-outline {
        color: $purple-light;
      }
    }

    &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float {
      .mat-form-field-label {
        transform: translateY(-1.1em) scale(0.75);
        width: 133.33333%;
      }

      .mat-form-field-label-wrapper {
        top: -1.4em;
      }

      &:not(.mat-form-field-disabled, .mat-form-field-invalid, .ng-untouched) {
        .mat-form-field-outline,
        .mat-form-field-label {
          color: $purple-light;
        }
      }
    }

    &.mat-form-field-appearance-outline {
      .mat-form-field-subscript-wrapper {
        top: auto;
        padding: 0;
      }
    }

    .mat-form-field-suffix {
      .text-suffix {
        position: relative;
        bottom: 5px;
      }
    }
  }

  &.mat-form-field-appearance-outline.mat-form-field-month-year-picker {
    .mat-form-field-wrapper {
      padding-bottom: 0;

      .mat-form-field-infix {
        padding: 0.3em 0 0.5em 0;
      }

      .mat-form-field-prefix .mat-datepicker-toggle {
        .mat-icon-button {
          width: 1.8rem;
          height: 1.8rem;
          margin-right: 0.5rem;
          color: $purple-light;
        }
      }
    }

    &.mat-form-field-can-float.mat-form-field-should-float {
      .mat-form-field-label,
      .mat-form-field-outline {
        color: $purple-light;
      }
    }
  }

  &.mat-form-field-small.mat-form-field-appearance-outline.mat-form-field-invalid.ng-dirty.mat-form-field-can-float.mat-form-field-should-float {
    .mat-form-field-label {
      color: #f44336;
    }
  }

  .mat-form-field-required-marker {
    display: none;
  }
}
