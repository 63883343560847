.mat-list__header {
  background-color: $purple;
  min-height: 65px;
  color: white;
  text-align: center;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 200;

  .mat-list__header-col {
    font-family: $font-family-bold;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1.6rem;
    flex: 1 1 12.4rem;
    @include font-size(h5);
  }

  .mat-list__header-col + .mat-list__header-col {
    border-left: 1px solid $purple-separator;
  }
}

mat-list {
  padding-top: 0 !important;
  margin-bottom: 1.6rem !important;
  font-size: $font-size-base !important;

  mat-list-item {
    margin-bottom: 0.6rem;
    background-color: $white;
    box-shadow: 0 0.5px 2px 0 rgba(43, 59, 93, 0.29);
    font-size: $font-size-base !important;

    &:hover {
      box-shadow: 0 0 13px 0 rgba(81, 39, 127, 0.29), 0 1px 2px 0 rgba(81, 39, 127, 0.29);
    }

    .mat-list-item-content {
      color: $purple;
      padding: 0 !important;

      > div {
        width: 100%;
      }
    }
  }
}

.mat-list-col {
  text-align: center;
  flex: 1 1 12.4rem;
  color: $purple;
}
