.mat-calendar {
  min-width: 250px;
}

.mat-calendar-period-button {
  color: $purple-light !important;
}

.mat-calendar-body-selected {
  background-image: linear-gradient(135deg, #7a2d76, #4b138a);
}

.mat-datepicker-content {
  margin-top: 10px;

  .mat-calendar-body-selected {
    background-image: linear-gradient(135deg, #7a2d76, #4b138a);
  }
}

.mat-calendar-body-today {
  border-color: $purple !important;
}

.mat-calendar-next-button,
.mat-calendar-previous-button {
  color: $purple-light !important;

  &.mat-icon-button {
    height: 4rem;
    width: 4rem;
  }
}

.mat-calendar-arrow {
  border-top-color: $purple-light !important;
}

.mat-calendar-body-label {
  color: transparent;
  padding: 0 !important;
}

.mat-calendar-controls {
  margin-top: 0 !important;
}

.mat-calendar-table-header th {
  padding: 0 !important;
}

.mat-calendar-table-header-divider::after {
  opacity: 0;
}

.mat-calendar-body-cell-content {
  font-family: $font-family-base;
  width: 100% !important;
  height: 100% !important;
  @include font-size(h5);
}

.month-year-picker-dialog {
  .mat-calendar-period-button {
    pointer-events: none;
  }

  .mat-calendar-arrow {
    display: none;
  }
}
