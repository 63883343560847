.mat-button-toggle-group {
  border-radius: 4px !important;
  box-shadow: none !important;
  border: 1px solid $purple-light;
  font-family: $font-family-semibold;
  height: $button-height;
  min-width: fit-content;

  .mat-button-toggle {
    text-transform: uppercase;
    font-family: $font-family-semibold;
    background-color: $white;
    color: $purple-light;
    outline: 1px solid;
    @include font-size(h5);

    .mat-icon {
      color: inherit;
      font-size: 17px;
      width: 17px;
      height: 17px;
      margin-right: 5px;
      display: none;
    }

    &-checked {
      background: $gradient-purple;
      color: $white;

      .mat-icon {
        display: block;
      }
    }

    .mat-button-toggle-label {
      padding: 0;
      margin: 0;
      height: 100%;
    }

    .mat-button-toggle-label-content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      line-height: 3.6rem;
    }
  }
}
