$mat-card-header-height: 6rem;

.mat-card {
  background-color: $grey-ultra;
  border-radius: 4px !important;
  padding: 0 !important;
}

.mat-card-content {
  padding: $margin-bottom-base * 2;
  border-radius: 0 0 4px 4px;
}

.mat-card-header {
  height: $mat-card-header-height;
  display: flex;
  align-items: center;
  padding: 0 $margin-bottom-base * 2;
  background-color: $white;
  border-bottom: 1px solid $grey-lighter;
  border-radius: 4px 4px 0 0;

  &--s {
    height: $mat-card-header-height - 2rem;
  }

  &--sticky {
    position: sticky;
    top: 0;
  }

  .mat-card-header-text {
    margin: 0;
  }

  .mat-card-title {
    margin: 0 !important;
    color: $purple;
    font-family: $font-family-bold;
    @include font-size(h2);
  }
}
