.mat-primary:enabled {
  &.mat-flat-button,
  &.mat-raised-button {
    background: $gradient-purple;
  }
}

.mat-secondary:enabled {
  &.mat-flat-button,
  &.mat-raised-button,
  &.mat-mini-fab {
    background: $grey-lighter;
    color: $purple-light;
  }
}

.mat-button,
.mat-flat-button,
.mat-raised-button,
.mat-stroked-button {
  text-transform: uppercase;
  border-radius: 4px !important;
}

.mat-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mat-fab .mat-button-wrapper,
.mat-mini-fab .mat-button-wrapper {
  display: flex !important;
}

.mat-stroked-button {
  background-color: $white;

  .mat-button-wrapper > * {
    vertical-align: middle;
  }

  &:not(.mat-button-disabled) {
    border-color: $purple-light;
  }
}

button .mat-icon {
  font-size: 18px;
  height: 18px;
  width: 18px;
}

button:not(.mat-icon-button):not(.mat-fab):not(.mat-mini-fab) {
  .mat-icon {
    margin-right: 1rem;
  }
}

.half-in-buttons {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -17.5px;
}

button.mat-icon-button,
button.mat-mini-fab {
  height: $button-height;
  width: $button-height;
}
