@import '../_config/mixins';

html {
  /* switching to border-box model for all elements */
  box-sizing: border-box;

  /* set base font-size to equiv "10px", which is adapted to rem unit */
  font-size: 62.5%;

  /* IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
  font-size: calc(1em * 0.625);
}

body {
  margin: 0;
  @include font-size(base);

  background-color: $background-base;
  color: $color-base;
  font-family: $font-family-base;
  line-height: $line-height-base;
}

html,
body {
  height: 100%;
}

/* Headings */
h1,
.h1-like {
  @include font-size(h1);
  @if variable_exists(font-family-headings) and $font-family-headings != $font-family-base {
    font-family: $font-family-headings;
  }

  font-weight: $weight-medium;
}

h2,
.h2-like {
  @include font-size(h2);
  @if variable_exists(font-family-headings) and $font-family-headings != $font-family-base {
    font-family: $font-family-headings;
  }

  font-weight: $weight-bolder;
}

h3,
.h3-like {
  @include font-size(h3);

  font-family: $font-family-semibold;
}

h4,
.h4-like {
  @include font-size(h4);

  font-family: $font-family-semibold;
}

h5,
.h5-like {
  @include font-size(h5);

  font-family: $font-family-semibold;
}

h6,
.h6-like {
  @include font-size(h6);

  font-family: $font-family-semibold;
}

/* Vertical rythm */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: $headings-margin-bottom;
}

p,
address,
ol,
ul {
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
}

button {
  font-family: hurme_semibold, sans-serif;

  &:focus {
    outline: none;
  }
}

a,
a:hover,
a:active,
a:visited {
  text-decoration: none;
  color: $purple-light;
  cursor: pointer;
}

.star-table {
  background: $grey-ultra;
  color: $purple;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  &.spacing {
    border-spacing: 0 0.5rem;
  }

  th,
  td {
    padding: 0 1rem;
  }

  thead {
    background: $table-header-bg;
    color: white;
    text-align: center;
    vertical-align: middle;

    th {
      background-color: $table-header-bg;
      position: sticky;
      top: 0;
      z-index: 200;
      font-family: $font-family-bold;
      line-height: 3.4rem;
      border-right: 1px solid $table-header-border-color;
      @include font-size(h5);

      &.top-34 {
        top: 3.4rem;
      }
    }

    th.th-xl {
      line-height: 6.8rem;
    }

    tr + tr th {
      border-top: 1px solid $purple-separator;
      font-family: $font-family-semibold;
      @include font-size(h6);
    }

    tr.multiple-line {
      height: 6.8rem;

      th {
        line-height: 1.5rem;
      }
    }
  }

  tbody {
    @include font-size(h5);

    &.small-table {
      @include font-size(h4);
    }

    .gas-day {
      font-family: $font-family-bold;
      color: $purple-light;
      position: relative;
      @include font-size(h4);
    }
  }

  tbody:not(.collapsible-row) {
    tr:not(.spacer) {
      background-color: #fff;
      box-shadow: $table-row-shadow;
      height: $table-row-height;
    }
  }

  tbody.highlight-body {
    box-shadow: $table-row-shadow;

    tr:not(.spacer) {
      box-shadow: none;
      border-color: transparent;
    }

    &:hover {
      box-shadow: $table-row-shadow-hover;
    }
  }

  tbody:not(.collapsible-row):not(.highlight-body) {
    tr:not(.spacer) {
      &:hover {
        box-shadow: $table-row-shadow-hover;
      }
    }
  }

  tr.spacer {
    height: 0.5rem;
    background: transparent;
  }

  td {
    text-align: center;
  }

  tbody.cell-bordered {
    td[rowspan] {
      border-right: 1px solid $grey-lighter;
    }

    td + td {
      border-left: 1px solid $grey-lighter;
    }

    tr + tr td {
      border-top: 1px solid $grey-lighter;
    }
  }
}

.separator {
  border: none;
  border-bottom: 1px solid $grey-lighter;
  margin: 30px 0;

  &.full-width {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    position: relative;
    right: 50%;
    width: 100vw;
  }

  &--small {
    width: 4rem;
  }

  &-v {
    @extend .separator;

    border-bottom: none;
    border-left: 1px solid $grey-lighter;
    margin: 0 30px;
  }
}

app-toolbar {
  .title {
    font-family: $font-family-bold;
    @include font-size(h2);
  }
}
