.mat-simple-snackbar-action {
  color: $white;
}

.mat-snack-bar-container.error {
  background-color: $sanguine-orange;
}

.mat-snack-bar-container.success {
  background-color: $green;
}
