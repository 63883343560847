@import '~bootstrap/scss/variables';

$grid-breakpoints: (
  xs: 0,
  sm: $small,
  md: $medium,
  lg: $large,
  xl: $extra-large,
  xxl: $xx-large,
  xxxl: $xxx-large,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1360px,
  xxxl: $xxx-large,
);

$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 3,
    ),
    6: (
      $spacer * 6,
    ),
  ),
  $spacers
);

$primary: $purple;
$secondary: $grey;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
);
