/* Font familly */
@font-face {
  font-family: 'hurme_thin';
  font-style: normal;
  font-weight: normal;
  src: url('~libs/ui/src/assets/fonts/hurmegeometricsans4_thin-webfont.woff') format('woff'),
    url('~libs/ui/src/assets/fonts/hurmegeometricsans4_thin-webfont.woff2') format('woff2'),
    url('~libs/ui/src/assets/fonts/hurmegeometricsans4_thin-webfont.ttf') format('truetype');
}

@font-face {
  font-family: 'hurme_light';
  font-style: normal;
  font-weight: normal;
  src: url('~libs/ui/src/assets/fonts/hurmegeometricsans4_light-webfont.woff') format('woff'),
    url('~libs/ui/src/assets/fonts/hurmegeometricsans4_light-webfont.woff2') format('woff2'),
    url('~libs/ui/src/assets/fonts/hurmegeometricsans4_light-webfont.ttf') format('truetype');
}

@font-face {
  font-family: 'hurme_semibold';
  font-style: normal;
  font-weight: normal;
  src: url('~libs/ui/src/assets/fonts/hurmegeometricsans4_semibold-webfont.woff') format('woff'),
    url('~libs/ui/src/assets/fonts/hurmegeometricsans4_semibold-webfont.woff2') format('woff2'),
    url('~libs/ui/src/assets/fonts/hurmegeometricsans4_semibold-webfont.ttf') format('truetype');
}

@font-face {
  font-family: 'hurme_bold';
  font-style: normal;
  font-weight: normal;
  src: url('~libs/ui/src/assets/fonts/hurmegeometricsans4_bold-webfont.woff') format('woff'),
    url('~libs/ui/src/assets/fonts/hurmegeometricsans4_bold-webfont.woff2') format('woff2'),
    url('~libs/ui/src/assets/fonts/hurmegeometricsans4_bold-webfont.ttf') format('truetype');
}

@font-face {
  font-family: 'hurme_black';
  font-style: normal;
  font-weight: normal;
  src: url('~libs/ui/src/assets/fonts/hurmegeometricsans4_black-webfont.woff') format('woff'),
    url('~libs/ui/src/assets/fonts/hurmegeometricsans4_black-webfont.woff2') format('woff2'),
    url('~libs/ui/src/assets/fonts/hurmegeometricsans4_black-webfont.ttf') format('truetype');
}

@font-face {
  font-family: 'hurme_regular';
  font-style: normal;
  font-weight: normal;
  src: url('~libs/ui/src/assets/fonts/hurmegeometricsans4_regular-webfont.woff') format('woff'),
    url('~libs/ui/src/assets/fonts/hurmegeometricsans4_regular-webfont.woff2') format('woff2'),
    url('~libs/ui/src/assets/fonts/hurmegeometricsans4_regular-webfont.ttf') format('truetype');
}
