@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/icon?family=Material+Icons'); /* stylelint-disable-line no-invalid-position-at-import-rule */

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-typography-config(
  $font-family: 'hurme_regular, sans-serif',
  $display-4: mat.define-typography-level(get-size('h1'), 2.4rem, bold, hurme_bold),
  $display-3: mat.define-typography-level(get-size('h2'), 1.8rem, bold, hurme_bold),
  $display-2: mat.define-typography-level(get-size('h3'), 1.6rem, bold, hurme_bold),
  $display-1: mat.define-typography-level(get-size('h4'), 1.4rem, bold, hurme_bold),
  $headline: mat.define-typography-level(get-size('h1'), 2.4rem, bold, hurme_bold),
  $title: mat.define-typography-level(get-size('h2'), 1.8rem, bold, hurme_bold),
  $subheading-2: mat.define-typography-level(get-size('h3'), 1.6rem, bold, hurme_bold),
  $subheading-1: mat.define-typography-level(get-size('h4'), 1.4rem, bold, hurme_bold),
  $body-2: mat.define-typography-level(get-size('base'), 20px, 500),
  $body-1: mat.define-typography-level(get-size('base'), 20px, 400),
  $caption: mat.define-typography-level(get-size('h5'), 20px, 400),
  $button: mat.define-typography-level(get-size('base'), 14px, 500, hurme_semibold),
  $input: mat.define-typography-level(inherit, 1.125, 400),
);

// Override the typography in the core CSS.
@include mat.core($custom-typography);

$purple-palette: (
  50: #ebe4f0,
  100: #cebdda,
  200: #ae91c1,
  300: #8d64a8,
  400: #744395,
  500: #5c2282,
  600: #541e7a,
  700: #4a196f,
  800: #411465,
  900: #300c52,
  A100: #c088ff,
  A200: #a455ff,
  A400: #8922ff,
  A700: #7c08ff,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #fff,
    A700: #fff,
  ),
);

$purple-light-palette: (
  50: #f0e2f9,
  100: #dab6f1,
  200: #c186e8,
  300: #a856de,
  400: #9631d7,
  500: #830dd0,
  600: #7b0bcb,
  700: #7009c4,
  800: #6607be,
  900: #5303b3,
  A100: #eadcff,
  A200: #cba9ff,
  A400: #ac76ff,
  A700: #9d5dff,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);

$custom-mat-light-theme-foreground: (
  base: $color-base,
  divider: rgba(black, 0.12),
  dividers: rgba(black, 0.12),
  disabled: rgba(black, 0.38),
  disabled-button: rgba(black, 0.26),
  disabled-text: rgba(black, 0.38),
  hint-text: rgba(black, 0.38),
  secondary-text: rgba(black, 0.54),
  icon: rgba(black, 0.54),
  icons: rgba(black, 0.54),
  text: $color-base,
  slider-min: rgba(black, 0.87),
  slider-off: rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);

@function custom-mat-define-light-theme($primary, $accent, $warn: mat.define-palette(mat.$red-palette)) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: false,
    foreground: $custom-mat-light-theme-foreground,
    background: mat.$light-theme-background-palette
  );
}

$terega-app-theme: custom-mat-define-light-theme(mat.define-palette($purple-palette), mat.define-palette($purple-light-palette));

@include mat.all-component-themes($terega-app-theme);

//@include mat.button-theme($terega-app-theme);
//@include mat.button-toggle-theme($terega-app-theme);
//@include mat.autocomplete-theme($terega-app-theme);
//@include mat.select-theme($terega-app-theme);
