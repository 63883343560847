.mat-select-value {
  color: $purple-light;
}

.mat-select-arrow {
  visibility: hidden;
}

.mat-optgroup {
  &-label {
    color: $purple-light;
    font-family: $font-family-semibold;
  }
}

.mat-option {
  font-family: $font-family-base;
  color: $purple-light;

  &.mat-active {
    color: $purple-light;
    font-family: $font-family-semibold;
  }

  &.clear-option {
    border-bottom: solid 1px $purple-light;
  }
}

.mat-select-panel {
  max-width: 100% !important;
}

.select {
  .mat-select-placeholder {
    color: $purple-light !important; //TODO : enlever ce !important quand les fichiers material de lub ui non V2 auront été supprimés
  }
}
