.ff-thin {
  font-family: $font-family-thin;
}

.text-fushia {
  color: $fushia;
}

.text-purple-light {
  color: $purple-light;
}

.bg-purple-flashy {
  background: $purple-flashy;
}

.bg-warm-purple {
  background: $purple-light;
}

.bg-blue-sky {
  background: $blue-sky;
}

.bg-green-apple {
  background: $green-apple;
}

.bg-fushia {
  background: $fushia;
}

.pointer {
  cursor: pointer;
}

.hyperlink {
  color: $blue-sky !important;
  text-decoration: underline !important;
  font-family: $font-family-semibold;
}
