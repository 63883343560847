@import '~bootstrap/scss/utilities/background';
@import '~bootstrap/scss/utilities/float';
@import '~bootstrap/scss/utilities/sizing';
@import '~bootstrap/scss/utilities/spacing';
@import '~bootstrap/scss/utilities/text';

.w-10 {
  width: 10%;
}

.w-80 {
  width: 80%;
}

.font-weight-light {
  font-family: $font-family-light;
}

.font-weight-normal {
  font-family: $font-family-base;
}

.font-weight-semibold {
  font-family: $font-family-semibold;
}

.font-weight-bold {
  font-family: $font-family-bold;
}
